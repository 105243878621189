import React, { useMemo } from 'react';
import { DataTable, DataTableCell, InputTableRow, useApp } from '@dex/bubl-dash';
import { formatNum } from '@dex/bubl-helpers';

const OrdersLineItems: React.FC<any> = (props: any) => {

  const { data } = props;

  const app = useApp();

  const columns = [
    {
      label: "Item",
    },
    {
      label: "Cost",
      alignEnd: true,
    },
    {
      label: "Quantity",
      alignMiddle: true,
    },
    {
      label: "Total",
      alignEnd: true,
    },
  ];

  return useMemo(() => (

    <>

      <DataTable
        data={null}
        columns={columns}
        className={"mini-table order-table"}
      >

        {data.cart.items.map((item: any, index: number) => (
          <InputTableRow className={"invoice-line-item"}>

            <DataTableCell style={{ width: "40%" }} className={"invoice-line-desc"}>

              {item.product_name ? item.product_name : ""}

            </DataTableCell>

            <DataTableCell alignEnd className={"invoice-line-quantity"}>

              {item.price ? item.price : ""}

            </DataTableCell>

            <DataTableCell alignMiddle className={"invoice-line-value"}>

              x {item.quantity ? item.quantity : ""}

            </DataTableCell>

            <DataTableCell alignEnd className={"invoice-line-value"}>

              {item.line_subtotal ? item.line_subtotal : ""}

            </DataTableCell>

          </InputTableRow>
        ))}

        <InputTableRow>

          <DataTableCell colSpan={3} alignEnd className="invoice-line-label">
            Subtotal
          </DataTableCell>

          <DataTableCell alignEnd>
            {formatNum(data.cart.totals.subtotal, 2, "")}
          </DataTableCell>

        </InputTableRow>

        <InputTableRow>

          <DataTableCell colSpan={3} alignEnd className="invoice-line-label">
            Shipping Total
          </DataTableCell>

          <DataTableCell alignEnd>
            {formatNum(data.cart.totals.shipping_total, 2, "")}
          </DataTableCell>

        </InputTableRow>

        <InputTableRow>

          <DataTableCell colSpan={3} alignEnd className="invoice-line-label">
            Discount Total
          </DataTableCell>

          <DataTableCell alignEnd>
            {formatNum(data.cart.totals.discount_total, 2, "")}
          </DataTableCell>

        </InputTableRow>

        <InputTableRow>

          <DataTableCell style={{ width: "10%" }} colSpan={3} alignEnd className="invoice-line-label">
            <strong>Total</strong>
          </DataTableCell>

          <DataTableCell style={{ width: "10%" }} alignEnd>
            <strong>{formatNum(data.cart.totals.total, 2, "$ ")}</strong>
          </DataTableCell>

        </InputTableRow>


      </DataTable>

    </>

  ), [data]);

}

export default OrdersLineItems;