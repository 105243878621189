import React, { useCallback, useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import { Space } from '@dex/bubl-dash';
import OrdersLineItems from './OrdersLineItems';
import OrdersApi from '../OrdersApi';
import { Button } from '@dex/bubl-dash';

const OrdersssSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    const check = OrdersApi.update(data.id, "checkStatus");

    const handleCheckStatus = useCallback(() => {

        check.run();

    }, [])

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Order Details"}
                    actions={<>
                        {(data.status === "error" || data.status === "failed") &&
                            <Button
                                size='regular'
                                type='faded'
                                label={"Check Payment Status"}
                                onClick={handleCheckStatus}
                                loading={check.loading}
                            />
                        }
                    </>}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "WordPress Order ID",
                                key: "orderId",
                            },
                        ]}
                        columnB={[
                            {
                                label: "Status",
                                key: "status",
                                format: "titleCase"
                            },
                        ]}
                        columnC={[
                            {
                                label: "Created Date",
                                key: "created",
                                format: "OnAndBy",
                            },
                        ]}
                    />

                    <Seperator
                        heading={"Billing Details"}
                        top={"auto"}
                        bottom={"auto"}
                    />

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Billing Address",
                                key: "billingAddress",
                                render: (value, row) => {
                                    if (row.orderResponse.billing) {
                                        return (
                                            <div>
                                                <p>{row.orderResponse.billing.first_name} {row.orderResponse.billing.last_name}</p>
                                                <p>{row.orderResponse.billing.address_1}</p>
                                                <p>{row.orderResponse.billing.city}, {row.orderResponse.billing.state} {row.orderResponse.billing.postcode}</p>
                                                <p>{row.orderResponse.billing.country}</p>
                                            </div>
                                        );
                                    } else if (row.user.billingAddress) {
                                        return (
                                            <div>
                                                <p>{row.user.billingAddress.first_name} {row.user.billingAddress.last_name}</p>
                                                <p>{row.user.billingAddress.address_1}</p>
                                                <p>{row.user.billingAddress.city}, {row.user.billingAddress.state} {row.user.billingAddress.postcode}</p>
                                                <p>{row.user.billingAddress.country}</p>
                                            </div>
                                        );
                                    }
                                    return "-";
                                }

                            },
                            {
                                label: "Shipping Address",
                                key: "shippingAddress",
                                render: (value, row) => {
                                    if (row.orderResponse.shipping) {
                                        return (
                                            <div>
                                                <p>{row.orderResponse.shipping.first_name} {row.orderResponse.shipping.last_name}</p>
                                                <p>{row.orderResponse.shipping.address_1}</p>
                                                <p>{row.orderResponse.shipping.city}, {row.orderResponse.shipping.state} {row.orderResponse.shipping.postcode}</p>
                                                <p>{row.orderResponse.shipping.country}</p>
                                            </div>
                                        );
                                    } else if (row.user.shippingAddress) {
                                        return (
                                            <div>
                                                <p>{row.user.shippingAddress.first_name} {row.user.shippingAddress.last_name}</p>
                                                <p>{row.user.shippingAddress.address_1}</p>
                                                <p>{row.user.shippingAddress.city}, {row.user.shippingAddress.state} {row.user.shippingAddress.postcode}</p>
                                                <p>{row.user.shippingAddress.country}</p>
                                            </div>
                                        );
                                    }
                                    return "-";
                                }

                            },
                        ]}
                        columnB={[
                            {
                                label: "Payment ID",
                                key: "paymentId",
                            },
                            {
                                label: "Transaction ID",
                                key: "orderResponse.transaction_id",
                            },
                            {
                                label: "Payment Method",
                                render: (value, row) => {
                                    if (row.orderResponse.payment_method || row.orderResponse.payment_method_title) {
                                        return row.orderResponse.payment_method + " (" + row.orderResponse.payment_method_title + ")"
                                    }
                                    return "-"
                                }
                            },
                        ]}
                        columnC={[
                            {
                                label: "Date Paid",
                                key: "orderResponse.date_paid",
                                format: "dateDay",
                            },

                        ]}
                    />

                    <Seperator
                        heading={"Cart Details"}
                        top={"auto"}
                        bottom={"auto"}
                    />

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Coupons",
                                key: "cart.coupons",
                                render: (value, row) => {
                                    if (row.cart && row.cart.coupons && row.cart.coupons.length > 0) {
                                        return (
                                            <div>
                                                {row.cart.coupons.map((coupon, index) => (
                                                    <div key={index}>
                                                        <p>{coupon.coupon}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        );
                                    }
                                    return "-";
                                }
                            },
                        ]}
                        columnB={[
                            {
                                label: "Shipping Method",
                                key: "cart.shipping_methods",
                                render: (value, row) => {

                                    const shippingMethodsArray = data.cart && data.cart.shipping_methods ? Object.values(data.cart.shipping_methods) : [];

                                    if (shippingMethodsArray.length > 0) {
                                        return (
                                            <div>
                                                {shippingMethodsArray.map((method: any, index) => (
                                                    <div key={index}>
                                                        <p>{method.label} ($ {method.cost})</p>
                                                    </div>
                                                ))}
                                            </div>
                                        );
                                    }
                                    return "-";
                                }
                            },
                        ]}
                    />

                    <Space />

                    <OrdersLineItems
                        data={data}
                    />

                    {/* <MetaTable
            data={data}
            columnA={[
              {
                label: "Cart Key",
                key: "cart.cart_key",
                format: "titleCase"
              },
            ]}
            columnB={[
              {
                label: "Total",
                key: "cart.totals.total",
                format: "number",
                prefix: "$US "
              },
              {
                label: "Subtotal",
                key: "cart.totals.subtotal",
                format: "number",
                prefix: "$US "
              },
            ]}
            columnC={[
              {
                label: "Shipping Total",
                key: "cart.totals.shipping_total",
                format: "number",
                prefix: "$US "
              },
              {
                label: "Discount Total",
                key: "cart.totals.discount_total",
                format: "number",
                prefix: "$US "
              },
            ]}
          /> */}


                </Panel>

            </Col>

            <Col className={'side'}>

                <ActivityPanel parent={data} />

            </Col>

        </Row >

    ), [data, check.loading]);

}

export default OrdersssSingleView;
